import React, { useEffect, useState } from 'react';
import Preloader from '../elements/Preloader';
import HelmetReact from '../elements/HelmetReact';
import Breadcrumb from '../components/Breadcrumb';
import GoalArea from '../components/GoalArea';
import AboutOne from '../components/AboutOne';
import ProcessThree from '../components/ProcessThree';
import TestimonialOne from '../components/TestimonialOne';
import CounterOne from '../components/CounterOne';
import WhyChoose from '../components/WhyChoose';
import FooterThree from '../components/FooterThree';
import Newsletter from '../components/Newsletter';
import HeaderThree from '../components/HeaderThree';

const About = () => {
    let [active, setActive] = useState(true);
    useEffect(() => {
        setTimeout(function() {
            setActive(false);
        }, 500);
    }, []);
    return (
        <>
            {/* Preloader */}
            {active === true && <Preloader/>}

            {/* Helmet */}
            <HelmetReact title={'关于我们'}/>

            {/* HeaderThree */}
            <HeaderThree/>

            {/* Breadcrumb */}
            <Breadcrumb title="关于我们"/>

            {/* GoalArea */}
            <GoalArea/>

            {/* AboutOne */}
            <AboutOne/>

            {/* ProcessThree */}
            <ProcessThree/>

            {/* TestimonialOne */}
            <div className="space">
                <TestimonialOne/>
            </div>

            {/* CounterOne */}
            <CounterOne/>

            {/* WhyChoose */}
            <div className="space">
                <WhyChoose/>
            </div>

            {/* Newsletter */}
            <Newsletter/>

            {/* FooterThree */}
            <FooterThree/>


        </>
    );
};

export default About;
