import React from 'react'
import { Link } from 'react-router-dom'

const ServiceView = () => {
    return (
        <div className="service-area-1 space overflow-hidden">
            <div className="container">
                <div className="row gx-30 gy-30 justify-content-center">
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-1.jpg" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">定制网站式设计</Link>
                                </h4>
                                <p className="service-card_text">
                                    根据客户需求提供个性化服务
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Read More <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-2.jpg" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">日本法人口座申请专用网站</Link>
                                </h4>
                                <p className="service-card_text">
                                    提供多种现有模板供客户选择
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Read More <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-3.jpg" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">电商网站</Link>
                                </h4>
                                <p className="service-card_text">
                                    支持在线支付和商品管理功能
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Read More <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-4.jpg" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">SEO 优化</Link>
                                </h4>
                                <p className="service-card_text">
                                    提升网站在搜索引擎中的排名
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Read More <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-5.jpg" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">维护与更新</Link>
                                </h4>
                                <p className="service-card_text">
                                    提供长期的技术支持和内容更新服务
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Read More <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-6.jpg" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">保护隐私</Link>
                                </h4>
                                <p className="service-card_text">
                                    保护客户的个人信息和数据
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Read More <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ServiceView