import React from 'react'
import { Link } from 'react-router-dom'

const TeamAll = () => {
    return (
        <div className="team-area-1 space">
            <div className="container">
                <div className="row gy-30 gx-30">
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-1.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Bizmaster" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Bizmaster" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    林宇恒
                                </h4>
                                <span className="team-card_desig">产品经理</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-2.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Bizmaster" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Bizmaster" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    陈晓彤
                                </h4>
                                <span className="team-card_desig">平面设计师</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-3.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Bizmaster" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Bizmaster" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    李浩
                                </h4>
                                <span className="team-card_desig">全栈开发工程师</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TeamAll