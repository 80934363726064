import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RouteScrollToTop from './elements/RouteScrollToTop';
import ScrollToTop from 'react-scroll-to-top';
import HomeThree from './pages/HomeThree';
import About from './pages/About';
import Service from './pages/Service';
import Project from './pages/Project';
import Blog from './pages/Blog';
import Pricing from './pages/Pricing';
import Team from './pages/Team';
import Contact from './pages/Contact';
import Error from './pages/Error';

const App = () => {
    return (
        <>
            <BrowserRouter>
                <RouteScrollToTop/>
                <ScrollToTop smooth color="#196164"/>
                <Routes>
                    <Route exact path="/" element={<HomeThree/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/service" element={<Service/>}/>
                    <Route exact path="/project" element={<Project/>}/>
                    <Route exact path="/blog" element={<Blog/>}/>
                    <Route exact path="/pricing" element={<Pricing/>}/>
                    <Route exact path="/team" element={<Team/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
