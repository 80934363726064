import React, { useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import HelmetReact from "../elements/HelmetReact";
import HeaderThree from "../components/HeaderThree";
import Breadcrumb from "../components/Breadcrumb";
import FooterThree from "../components/FooterThree";
import Newsletter from "../components/Newsletter";
import PortfolioAll from "../components/PortfolioAll";



const Project = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 500);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Helmet */}
      <HelmetReact title={"客户案例"} />

      {/* HeaderThree */}
      <HeaderThree />

      {/* Breadcrumb */}
      <Breadcrumb title="客户案例" />

      {/* PortfolioAll */}
      <PortfolioAll />

      {/* Newsletter */}
      <Newsletter />

      {/* FooterThree */}
      <FooterThree />

    </>
  );
};

export default Project;
