import React from "react";
import { Link } from "react-router-dom";

const AboutOne = () => {
  return (
    <div className="about-area-1 position-relative space-top">
      <div className="about1-shape-img1">
        <img
          className="about1-shape-img-1"
          src="assets/img/normal/about_shape1-1.jpg"
          alt="img"
        />
      </div>
      <div className="about1-shape-img2">
        <img
          className="about1-shape-img-2"
          src="assets/img/normal/about_shape1-2.png"
          alt="img"
        />
      </div>
      <div className="container">
        <div className="row gx-60 align-items-center">
          <div className="col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area me-xl-5 mb-20">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  关于我们
                </span>
                <h2 className="sec-title">助力您的业务成长</h2>
                <p className="sec-text mb-35">
                  借助我们的网站，助您迅速实现业务目标
                </p>
              </div>
              <div className="achive-about">
                <div className="achive-about_content">
                  <div className="achive-about_icon">
                    <i className="fas fa-check" />
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">日本法人口座申请专用版</h3>
                    <p className="achive-about_text">
                      专门针对中小型企业办理日本银行开户的要求设计
                    </p>
                  </div>
                </div>
              </div>
              <div className="achive-about">
                <div className="achive-about_content">
                  <div className="achive-about_icon">
                    <i className="fas fa-check" />
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">
                      企业个性化高级定制版
                    </h3>
                    <p className="achive-about_text">
                      完全根据客户需求量身定制，提供复杂业务功能和高级设计
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
