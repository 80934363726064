import React from 'react'

const GoalArea = () => {
    return (
        <div className="goal-area space">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="title-area">
                            <span className="sub-title">
                                <img src="assets/img/icon/title_left.svg" alt="shape" />
                                我们的目标
                            </span>
                            <h2 className="sec-title style2">专业合作，共创佳绩</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="tab-pane fade">
                        <div className="goal-list_wrapper">
                            
                        </div>
                    </div>
                    <div className="tab-pane fade active show">
                        <div className="goal-list_wrapper">
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">
                                        智慧的调度员
                                    </h4>
                                    <p>以用户需求为核心</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                优化资源配置
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                增强团队协作
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">出色的艺术家</h4>
                                    <p>高质量服务</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                吸引用户注意
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                提升品牌形象
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">
                                        可靠的守护者
                                    </h4>
                                    <p>让用户无比安全与信赖</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                持续运营
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                法律合规
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">能力的开发者</h4>
                                    <p>系统的训练与指导</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                提升员工技能
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                促进团队协作
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">
                                        才华横溢的设计师
                                    </h4>
                                    <p>为品牌注入独特的风格</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                增加用户参与感
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                提高用户满意度
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">创新推动者</h4>
                                    <p>持续引入行业前沿的技术</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                研发新的产品与服务
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                鼓励员工提出行想法
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">
                                        丰富的医生
                                    </h4>
                                    <p>定期维护网站及早发现问题</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                确保长期稳定运营
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                灵活应对市场变化
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="goal-content_wrapp">
                                <img src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                <div className="goal-content">
                                    <h4 className="box-title">出色的艺术家</h4>
                                    <p>用丰富的色彩与图像吸引用户</p>
                                    <div className="checklist">
                                        <ul>
                                            <li>
                                                <i className="fas fa-check"/>
                                                有趣有价值的内容
                                            </li>
                                            <li>
                                                <i className="fas fa-check"/>
                                                增加停留时间和互动可能性
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GoalArea